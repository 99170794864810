
import { Options, Vue } from 'vue-class-component';
import { ReviewBase, ReviewResource } from '@okendo/reviews-common';

import Bubble from '@/shared-components/Bubble.vue';
import Range from '@/shared-components/input-components/Range.vue';
import StarRating from '@/shared-components/StarRating.vue';
import Terms from '@/shared-components/Terms.vue';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { SiteReviewModule } from '@/store/modules/site-review';
import { postAnalytics, postSiteReview } from '@/utils/api';
import { scrollToFirstErrorElement, validateRules } from '@/utils/validationUtil';

@Options({
    components: {
        Bubble,
        Range,
        Terms,
        StarRating
    },
    store
})
export default class SiteReview extends Vue {
    isSending = false;
    hasSubmitted = false;

    mounted(): void {
        const siteRatingInputElement = document.querySelector('.c-okeSiteReview .c-starRating-radioButton:checked') as HTMLElement | undefined;
        siteRatingInputElement?.focus({ preventScroll: true });
    }

    get storeName(): string | undefined {
        return store.state.subscriber.storeName;
    }

    get description(): string {
        return this.formState.description;
    }

    set description(description: string) {
        store.commit<StoreMethod>({
            type: 'siteReview/UPDATE_DESCRIPTION',
            description
        });
    }

    get descriptionPlaceholderText(): string {
        return this.$t('How was your overall experience with us?');
    }

    get title(): string {
        return this.formState.title;
    }

    set title(title: string) {
        store.commit<StoreMethod>({
            type: 'siteReview/UPDATE_TITLE',
            title
        });
    }

    get titlePlaceholderText(): string {
        return this.$t('Review Title');
    }

    get siteRating(): string {
        return this.formState.siteRating;
    }

    set siteRating(siteRating: string) {
        store.commit<StoreMethod>({
            type: 'siteReview/UPDATE_SITE_RATING',
            siteRating
        });
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get isTestMode(): boolean {
        return store.state.order.reviewRequestId === 'test';
    }

    get isSiteReviewVisible(): boolean {
        const { hasSiteReview } = store.state.siteReview;
        const { name } = store.state.profile.reviewerProfile;
        return !hasSiteReview && !!name;
    }

    get submittedSuccessfully(): boolean {
        return store.state.siteReview.submittedSuccessfully;
    }

    async submitForm(): Promise<void> {
        if (this.isSending || this.isPreviewMode) {
            return;
        }

        this.hasSubmitted = true;

        if (!this.isFormValid()) {
            scrollToFirstErrorElement();
            return;
        }

        if (this.isTestMode) {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: 'Site Reviews are not submitted in Test Mode',
                    status: 'info'
                }
            });

            store.commit<StoreMethod>({
                type: 'siteReview/SUBMITTED_SUCCESSFULLY'
            });
            this.resetForm();
            return;
        }

        this.isSending = true;

        const {
            profile: { reviewerProfile },
            order: { reviewRequestId, elementId }
        } = store.state;

        try {
            const reviewer = this.getReviewer();
            const reviewerEmail = (reviewerProfile.email || '').trim();

            const reviewData: ReviewResource.CreateSiteReview.Review = {
                body: this.description,
                rating: parseInt(this.siteRating, 10),
                reviewer,
                reviewerEmail,
                title: this.title,
                reviewRequestId,
                elementId
            };

            await postSiteReview(reviewData);
            postAnalytics({ eventName: 'action-submitted-site-review' });
            store.commit<StoreMethod>({
                type: 'siteReview/SUBMITTED_SUCCESSFULLY'
            });
            this.resetForm();
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Review could not be submitted, please try again'),
                    status: 'fail'
                }
            });
        }
        finally {
            this.isSending = false;
        }
    }

    getReviewer(): ReviewResource.CreateReviewBase.Reviewer {
        const { profile: { reviewerProfile } } = store.state;

        if (!reviewerProfile.name) {
            throw new Error('reviewerProfile name is blank or empty, this function assumes the profile to have already been validated.');
        }

        const location = this.getReviewerLocation();

        return {
            name: reviewerProfile.name,
            avatarUrl: reviewerProfile.imageUrl,
            socialConnection: reviewerProfile.socialMediaType || undefined,
            customAvatar: reviewerProfile.customAvatar,
            socialConnectionUserId: reviewerProfile.socialMediaUserId,
            location
        };
    }

    getReviewerLocation(): ReviewBase.Reviewer.Location | undefined {
        const { profile: { reviewerProfile }, review: { review } } = store.state;

        const locationFromReview = review?.reviewer.attributes?.find(attribute => attribute.type === 'location');
        if (locationFromReview?.type === 'location') {
            const { countryCode, countryName, zoneCode } = locationFromReview.value;
            return {
                country: {
                    code: countryCode,
                    name: countryName
                },
                zoneCode
            };
        }

        const locationFromReviewRequest = reviewerProfile.countryCode ? {
            country: {
                code: reviewerProfile.countryCode
            }
        } : undefined;

        return locationFromReviewRequest;
    }

    isFormValid(): boolean {
        const rules = {
            'Please enter a <strong>Review Title</strong>': !!this.title,
            'Please describe your <strong>Overall Experience</strong>': !!this.description,
            'Please select a <strong>Star Rating</strong>': this.siteRating !== '0'
        };
        return validateRules(rules);
    }

    resetForm(): void {
        this.hasSubmitted = false;
        store.commit<StoreMethod>({
            type: 'siteReview/RESET_FORM'
        });
    }

    get formState(): SiteReviewModule {
        return store.state.siteReview;
    }
}
