import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "u-marginBottomUI--xxSmall" }
const _hoisted_3 = {
  class: "u-assistive-hidden",
  for: "title"
}
const _hoisted_4 = ["aria-label", "placeholder"]
const _hoisted_5 = {
  class: "u-assistive-hidden",
  for: "description"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  class: "c-button",
  type: "submit"
}
const _hoisted_8 = {
  key: 0,
  class: "c-loader c-loader--medium c-loader--buttonFontColor"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bubble = _resolveComponent("bubble")!
  const _component_star_rating = _resolveComponent("star-rating")!
  const _directive_analytics = _resolveDirective("analytics")!

  return (_ctx.isSiteReviewVisible)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
        class: "c-okeSiteReview"
      }, [
        (_ctx.submittedSuccessfully)
          ? (_openBlock(), _createBlock(_component_bubble, {
              key: 0,
              content: _ctx.$t('Review submitted, thanks for your feedback!')
            }, null, 8, ["content"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _withDirectives(_createVNode(_component_star_rating, {
                  modelValue: _ctx.siteRating,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.siteRating) = $event)),
                  hasError: _ctx.siteRating === '0' && _ctx.hasSubmitted
                }, null, 8, ["modelValue", "hasError"]), [
                  [_directive_analytics, {
                        eventName: 'change-site-review-rating',
                        label: 'siteRating',
                        value: _ctx.siteRating
                    }, "change"]
                ])
              ]),
              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Review Title')), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                "aria-label": _ctx.$t('Review Title'),
                class: _normalizeClass([{ hasError: !_ctx.title && _ctx.hasSubmitted }, "c-input u-marginBottomUI--xSmall"]),
                placeholder: _ctx.titlePlaceholderText,
                id: "title",
                type: "text",
                maxlength: "1000"
              }, null, 10, _hoisted_4), [
                [_vModelText, _ctx.title],
                [_directive_analytics, {
                    eventName: 'change-site-review-title',
                    label: 'titleLength'
                }, "change"]
              ]),
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Site review description')), 1),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.description) = $event)),
                class: _normalizeClass([{ hasError: !_ctx.description && _ctx.hasSubmitted }, "c-textarea c-input u-marginBottomUI--xSmall"]),
                placeholder: _ctx.descriptionPlaceholderText,
                id: "description",
                maxlength: "10000"
              }, null, 10, _hoisted_6), [
                [_vModelText, _ctx.description],
                [_directive_analytics, {
                    eventName: 'change-site-review-body',
                    label: 'bodyLength'
                }, "change"]
              ]),
              _createElementVNode("button", _hoisted_7, [
                (_ctx.isSending)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('Submit')), 1))
              ])
            ]))
      ], 32))
    : _createCommentVNode("", true)
}